<template>
  <v-container class="pl-10 pr-10" fluid>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
      >
        <v-row class="mb-5">
          <v-col cols="2">
            <h1>
              Property ID
            </h1>
          </v-col>
          <v-col>
            <h1 class="green--text">
              {{ this.$route.params.id }}
            </h1>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-btn
            dark
            color="orange"
            @click="showProperties('all')"
          >
            All
          </v-btn>
          &nbsp;
          <v-btn
            dark
            color="orange"
            @click="showProperties('Door/Window')"
          >
            Door/Window
          </v-btn>
          &nbsp;
          <v-btn
            dark
            color="orange"
            @click="showProperties('Energy update')"
          >
            Energy update
          </v-btn>
          &nbsp;
          <v-btn
            dark
            color="orange"
            @click="showProperties('SwitchGear Update')"
          >
            SwitchGear Update
          </v-btn>
          &nbsp;
          <v-btn
            dark
            color="orange"
            @click="showProperties('Movement')"
          >
            Movement
          </v-btn>
          &nbsp;
          <v-btn
            dark
            color="orange"
            @click="showProperties('Alarm')"
          >
            Alarm
          </v-btn>
        </v-row>
        <div id="myDiv" ref="myDiv">
          <v-expansion-panels
            v-for="one in bydays"
            :key="one.day"
          >
            <v-row>
              <v-col cols="12">
                <div class="daydiv">
                  {{ one.day }}
                </div>
              </v-col>
            </v-row>
            <v-expansion-panel
              v-for="hour in one.hours"
              :key="hour.time"
            >
              <v-expansion-panel-header v-if="hour.summary.doorwindow || hour.summary.energyupdate || hour.summary.switchgearupdate || hour.summary.movement || hour.summary.alarm" class=" lighten-2">
                <v-row>
                  <v-col cols="2">
                    <h3>
                      {{ hour.time }}
                    </h3>
                  </v-col>
                  <v-col>
                    <h4>
                      | <span v-if="hour.summary.doorwindow > 0" class="purple--text">Door/Window: {{ hour.summary.doorwindow }}, </span>
                      <span v-if="hour.summary.energyupdate > 0" class="blue--text">Energy Update: {{ hour.summary.energyupdate }}, </span>
                      <span v-if="hour.summary.switchgearupdate > 0" class="cyan--text">SwitchGear Update: {{ hour.summary.switchgearupdate }}, </span>
                      <span v-if="hour.summary.movement > 0" class="brown--text">Movement: {{ hour.summary.movement }}, </span>
                      <span v-if="hour.summary.alarm > 0" class="red--text">Alarm: {{ hour.summary.alarm }}</span>
                    </h4>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table id="infinite-list">
                  <thead>
                    <tr>
                      <th class="text-left">
                        Room Id
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                      <th class="text-left">
                        Type
                      </th>
                      <th class="text-left">
                        Current Consumption
                      </th>
                      <th class="text-left">
                        Total Consumption
                      </th>
                      <th class="text-left">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="log in hour.events"
                      :key="log.id"
                    >
                      <td>{{ log.roomId }}</td>
                      <td>{{ log.status }}</td>
                      <td>{{ log.type + ' - ' + log.devModel + ' - ' }}<span :class="spanColor(log.switchgear)">{{ log.switchgear }}</span></td>
                      <td>{{ log.currentConsumption }}</td>
                      <td>{{ log.totalConsumption }}</td>
                      <td>{{ new Date(log.createdAt).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-progress-linear
          v-if="pullingData"
          class="mt-2"
          indeterminate
          color="green"
        />
      </div>
    </v-slide-x-transition>
    <br>
    <div v-if="emptylogs">
      <div align="center">
        There are no logs for this selection yet.
      </div>
    </div>
    <div v-if="scrollEnd">
      <div align="center">
        You have reached the end of the logs.
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PropertyLogCompact',
  components: {
  },
  data () {
    return {
      selectedView: '1',
      showProgress: false,
      dialogDelete: false,
      deleteUserId: {},
      btnLoading: false,
      pullingData: false,
      manageProperty: false,
      pagination: {},
      search: '',
      footerProps: {
        'items-per-page-options': [50, 100, 150, 200]
      },
      propertyLogs: [],
      scrollEnd: false,
      logType: 'all',
      emptylogs: false,
      bydays: []
    }
  },
  watch: {
  },
  beforeMount () {
    this.loadPropertyLog(this.$route.params.id)
  },
  mounted () {
    // this.loadNextLogs(this.$route.params.id)
    window.addEventListener('scroll', this.handleScroll)
    this.observemyDivHeight()
  },
  methods: {
    loadPropertyLog (id) {
      this.pullingData = true
      this.$store.dispatch('getPropertyLog', { propertyId: id, skip: this.propertyLogs.length, type: this.logType }).then(response => {
        if (response.data.length === 0) {
          this.emptylogs = true
        }
        this.propertyLogs = response.data
        this.pullingData = false
        this.arrangeByTime()
      })
    },
    handleScroll () {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 50
      ) {
        this.loadMore()
      }
    },
    loadMore () {
      if (!this.pullingData & !this.scrollEnd) {
        this.pullingData = true
        this.$store.dispatch('getPropertyLog', { propertyId: this.$route.params.id, skip: this.propertyLogs.length, type: this.logType }).then(response => {
          if (response.data.length === 0) {
            this.scrollEnd = true
          }
          this.propertyLogs = this.propertyLogs.concat(response.data)
          this.pullingData = false
          this.arrangeByTime()
        })
      }
    },
    observemyDivHeight () {
      const resizeObserver = new ResizeObserver(() => {
        const panelheight = document.getElementById('myDiv').clientHeight
        if (panelheight < window.innerHeight) {
          this.loadMore()
        }
      })
      resizeObserver.observe(document.getElementById('myDiv'))
    },
    showProperties (type) {
      this.logType = type
      this.propertyLogs = []
      this.scrollEnd = false
      this.emptylogs = false
      this.loadPropertyLog(this.$route.params.id)
    },
    spanColor (text) {
      if (text === 'alarm OFF') {
        return 'red--text'
      } else if (text === 'alarm ON') {
        return 'green--text'
      } else {
        return ''
      }
    },
    arrangeByTime () {
      this.bydays = []
      this.propertyLogs.map(item => {
        const thisdate = new Date(item.createdAt)
        const thisdatestrorg = thisdate.toString()
        const thisdatestr = thisdatestrorg.slice(0, 15)
        var options = {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        }
        var timeString = thisdate.toLocaleString('en-US', options)
        // const thistime = new Date(item.createdAt).toISOString().slice(11, 23)
        const newDay = {
          day: thisdatestr,
          hours: [
            {
              time: '12:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '1:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '2:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '3:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '4:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '5:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '6:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '7:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '8:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '9:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '10:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '11:00 AM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '12:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '1:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '2:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '3:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '4:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '5:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '6:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '7:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '8:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '9:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '10:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            },
            {
              time: '11:00 PM',
              events: [],
              summary: {
                doorwindow: 0,
                alarm: 0,
                movement: 0,
                energyupdate: 0,
                switchgearupdate: 0
              }
            }
          ]
        }
        const index = this.bydays.findIndex(object => object.day === newDay.day)
        if (index === -1) {
          this.bydays.push(newDay)
        }
        // assign event to day
        var foundday = this.bydays.find(e => e.day === thisdatestr)
        const timeExplode1 = timeString.split(':')
        const timeExplode2 = timeString.split(' ')
        const searchTime = timeExplode1[0] + ':00 ' + timeExplode2[1]
        var foundhour = foundday.hours.find(e => e.time === searchTime)
        foundhour.events.unshift(item)
        if (item.type === 'Movement') {
          foundhour.summary.movement = foundhour.summary.movement + 1
        } else if (item.type === 'Alarm') {
          foundhour.summary.alarm = foundhour.summary.alarm + 1
        } else if (item.type === 'Door/Window') {
          foundhour.summary.doorwindow = foundhour.summary.doorwindow + 1
        } else if (item.type === 'Energy update') {
          foundhour.summary.energyupdate = foundhour.summary.energyupdate + 1
        } else if (item.type === 'SwitchGear Update') {
          foundhour.summary.switchgearupdate = foundhour.summary.switchgearupdate + 1
        }
      })
    }
  }
}
</script>

<style scoped>
  .cardtitle {
    font-size: 22px;
    color: #fff;
    background-color: #363636;
    margin-bottom: 20px;
  }
  .cardtitle i{
    color: #fff;
  }
  .cardsectitle {
    font-weight: bold;
    color: #363636;
  }
  .dark-text {
    font-size: 18px;
  }
  .black-text {
    font-weight: bold;
    color: #000;
  }
  .daydiv {
    background: #363636;
    color: #fff;
    display: block;
    font-weight: bold;
    font-size: 22px;
    padding: 10px;
  }
</style>
